
*{
    padding: 0;
    margin: 0;
  }
  .font-style{
    font-family: 'Poppins', sans-serif !important;
  
  
  }
  .ant-menu-horizontal, .ant-menu-item::after, .ant-menu-submenu::after {
    border: none !important;
    font-family: 'Poppins', sans-serif !important;

}
  /* .authorFont{
    font-family: 'Dancing Script', cursive !important;
  } */

  .dropdown:hover{
    color: #ff9b02 !important;
  }
  .activeClass:hover{
    color: #ff9b02 !important;
  }
  .activeClass{
    color: #ff9b02 !important;
  }
  .noActiveClass{
    color: white !important;
  }
  .fixed-buy-now-button {

    bottom: 0;
    left: 0;
    width: 100%;
    text-align: left;
    
  }