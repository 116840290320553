.media-container{
  width: 100%;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  flex-direction: column;
}
.content-container {
  background-color: #d9d5d5; /* Gray background color */
  width: 700px; 
  padding: 30px; 
  overflow-y: auto; 
  margin-bottom: 20px;
  
}
.heading {
  font-family:"Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 24px; 
  font-weight: 400;
  line-height: 30px;
  color: #d9d5d5; /* Set the text color */
  padding: 10px; /* Add padding inside the border */
  width: 700px;
  text-align: center; /* Center the text horizontally */
  background-color: #27285C;
}
.subheading {
  font-family: Domine, serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #000;
}
.content {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", 
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  margin-top: 20px;
  color: #000;
  text-align: left;
}
.vidstyle {
  border: none;
  overflow: hidden;
  width: 640px; 
  height: 360px;
  margin-top: 20px;
  margin-bottom: 10px
}

.date {
  font-family: Domine, serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ff9b02;
}

@media (max-width: 900px ) {
  .heading {
      width: 380px;
  }
  .content-container 
  {
      width: 380px;

  }
  .vidstyle {
      width: 320px;
      height: 180px; 
  }
}