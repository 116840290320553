.buttn {
    background-image: linear-gradient(to right, #314755 0%, #26a0da 51%, #314755 100%);
    width: 10rem;
    height: 3rem;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    cursor: pointer;
    border-radius: 10px;
    background-size: 200% auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: .4s;
}

@media (max-width: 900px) {
    .buttn {
        margin-top: 20px; /* This adds a top margin for screens smaller than 768px */
    }
}
   
   .buttn span:last-child {
    display: none;
   }
   
   .buttn:hover {
    background-position: right center !important; 
            color: #fff;
            text-decoration: none;
   }
   
   .buttn:active {
    background-color: #87dbd0;
   }