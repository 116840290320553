.zoom {
    transition: transform 0.5s !important;
    margin: 0 auto;
  }
  
  .zoom:hover {
    transform: scale(1.1);
  }
  
  .novel-link {
    color: white;
    text-decoration: none;
  }
  
  .novel-link:hover {
    text-decoration: underline;
    color: #0A8754;
  }